<!-- ColorLightbox.vue -->
<template>
  <Transition name="fade" appear>
    <div v-if="isOpen" class="lightbox" @click="close">
      <button class="close-button" @click="close">
        <span class="mdi mdi-close"></span>
      </button>
      <div class="lightbox-content">
        <img :src="imageUrl" :alt="imageAlt" @click.stop />
      </div></div
  ></Transition>
</template>

<script>
export default {
  name: "ColorLightbox",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    imageAlt: {
      type: String,
      default: "",
    },
  },
  emits: ["close"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      },
    },
  },
  mounted() {
    // Verhindert Scrolling wenn Lightbox offen ist
    if (this.isOpen) {
      document.body.style.overflow = "hidden";
    }
  },
  beforeUnmount() {
    // Stellt Scrolling wieder her
    document.body.style.overflow = "auto";
  },
};
</script>

<style lang="scss" scoped>
// Fade Transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// Optional: Zusätzliche Animation für den Content
.fade-enter-active .lightbox-content {
  transition: transform 0.5s ease;
}

.fade-enter-from .lightbox-content {
  transform: translateY(-20px);
  opacity: 0;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
    z-index: 1001;
    padding: 10px;

    &:hover {
      color: #e0e0e0;
    }
  }

  .lightbox-content {
    max-width: 90%;
    max-height: 90%;
    width: 1080px;

    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
</style>
