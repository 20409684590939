<!-- WishlistView.vue -->
<template>
  <div>
    <header class="main-header">
      <h2 class="h2-icon">Meine Merkliste</h2>
    </header>

    <div v-if="wishlistItems.length === 0" class="content empty-wishlist">
      <svg
        width="264"
        height="264"
        viewBox="0 0 264 264"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M263.25 14.0063L249.994 0.75L0.75 249.994L14.0063 263.25L32.7563 244.5H225.75C230.721 244.493 235.485 242.515 239 239C242.515 235.485 244.493 230.721 244.5 225.75V32.7563L263.25 14.0063ZM225.75 225.75H51.5063L124.566 152.691L146.869 174.994C150.385 178.509 155.153 180.484 160.125 180.484C165.097 180.484 169.865 178.509 173.381 174.994L188.25 160.125L225.75 197.597V225.75ZM225.75 171.075L201.506 146.831C197.99 143.316 193.222 141.342 188.25 141.342C183.278 141.342 178.51 143.316 174.994 146.831L160.125 161.7L137.841 139.416L225.75 51.5063V171.075ZM38.25 188.25V160.125L85.125 113.278L97.9969 126.159L111.272 112.884L98.3812 99.9938C94.8651 96.4787 90.0968 94.504 85.125 94.504C80.1532 94.504 75.3849 96.4787 71.8688 99.9938L38.25 133.613V38.25H188.25V19.5H38.25C33.2787 19.505 28.5125 21.482 24.9972 24.9972C21.482 28.5125 19.505 33.2787 19.5 38.25V188.25H38.25Z"
          fill="black"
        />
      </svg>
      <span>Merkliste leer</span>
    </div>

    <div v-else class="wishlist-content">
      <ul class="wishlist-items">
        <li v-for="item in wishlistItems" :key="item.id" class="wishlist-item">
          <div class="item-image">
            <router-link
              :to="`/produkte/${encodeURIComponent(item.name)}`"
              class="view-product"
            >
              <img :src="getImageUrl(item.image)" :alt="item.name"
            /></router-link>
          </div>
          <div class="item-content">
            <div class="ic-inner">
              <div class="item-brand">{{ item.brand }}</div>
              <h3 class="item-name">{{ item.name }}</h3>
              <router-link
                :to="`/produkte/${encodeURIComponent(item.name)}`"
                class="view-product"
              >
                Zum Produkt
                <span class="mdi mdi-arrow-right"></span>
              </router-link>
            </div>
          </div>
          <button @click="removeFromWishlist(item.id)" class="remove-btn">
            <span class="mdi mdi-close"></span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Cookies from "js-cookie";

export default {
  name: "WishlistView",
  setup() {
    const wishlistItems = ref([]);
    const WISHLIST_COOKIE = "rauch_wishlist";

    const loadWishlist = () => {
      const wishlist = Cookies.get(WISHLIST_COOKIE);
      wishlistItems.value = wishlist ? JSON.parse(wishlist) : [];
    };

    const removeFromWishlist = (productId) => {
      const updatedWishlist = wishlistItems.value.filter(
        (item) => item.id !== productId
      );
      wishlistItems.value = updatedWishlist;
      Cookies.set(WISHLIST_COOKIE, JSON.stringify(updatedWishlist), {
        expires: 30,
      });
    };

    const getImageUrl = (imageName) => {
      return require(`@/assets/produkte/${imageName}`);
    };

    onMounted(() => {
      loadWishlist();
    });

    return {
      wishlistItems,
      removeFromWishlist,
      getImageUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.empty-wishlist {
  text-align: center;
  padding: 40px 20px;

  svg {
    width: 80%;
    max-width: 240px;
    height: auto;
    margin: 0 auto 15px;
    opacity: 0.15;
  }

  span {
    font-size: 1.2em;
    display: block;
  }
}

.wishlist-items {
  list-style: none;
  padding: 0;
  margin: -30px 0 0 0;
}

.wishlist-item {
  display: grid;
  grid-template-columns: 200px 1fr 50px;
  gap: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  .item-image {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .item-content {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .item-brand {
      color: #adadad;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.56px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .item-name {
      font-size: 24px;
      color: #464646;
      margin: 0 0 16px;
    }

    .view-product {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      color: var(--main);
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .remove-btn {
    background: transparent;
    border: none;
    color: #adadad;
    cursor: pointer;
    padding: 8px;
    transition: color 0.3s ease;

    &:hover {
      color: var(--main);
    }

    .mdi {
      font-size: 24px;
    }
  }
}

@media (max-width: 768px) {
  .wishlist-item {
    grid-template-columns: 120px 1fr 40px;
    gap: 15px;
    padding: 15px 0;

    .item-content {
      .item-name {
        font-size: 20px;
      }
    }
  }
}
</style>
