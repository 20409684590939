<template>
  <div class="color-selector">
    <div
      v-for="(colorList, category) in colors"
      :key="category"
      class="color-category"
    >
      <h4>{{ category }}</h4>
      <div class="color-list">
        <div v-for="color in colorList" :key="color" class="color-item">
          <div class="color-swatch">
            <img
              v-if="getColorImage(color)"
              :src="getColorImage(color)"
              :alt="color"
              @click="openLightbox(color)"
              class="clickable"
            />
            <span v-else class="no-image"></span>
          </div>
          <div class="color-name">{{ color }}</div>
        </div>
      </div>
    </div>

    <!-- Lightbox Komponente -->
    <ColorLightbox
      :is-open="lightboxOpen"
      :image-url="selectedColorImage"
      :image-alt="selectedColorName"
      @close="closeLightbox"
    />
  </div>
</template>

<script>
import ColorLightbox from "./ColorLightbox.vue";

export default {
  name: "ColorSelector",
  components: {
    ColorLightbox,
  },
  props: {
    colors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lightboxOpen: false,
      selectedColorImage: "",
      selectedColorName: "",
    };
  },
  methods: {
    getColorImage(colorName) {
      // Diese Methode sollte die Bild-URL für den gegebenen Farbnamen zurückgeben
      // Sie müssen diese Logik entsprechend Ihren Anforderungen implementieren
      const colorImages = {
        "stonegrey": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/035_stone%20grey.png",
        "Dekor-Druck Eiche Sanremo hell": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0E6_Dekor-Druck%20Eiche%20Sanremo%20hell.png",
        "Dekor-Druck Eiche Sonoma": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/059_Dekor-Druck%20Eiche%20Sonoma.png",
        "seidengrau": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/068_seidengrau.png",
        "Dekor-Druck Eiche Stirling": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/077_Dekor-Druck%20Eiche%20Stirling.png",
        "Dekor-Druck Eiche Wotan": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/079_Dekor-Druck%20Eiche%20Wotan.png",
        "grau metallic": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/082_grau-metallic.png",
        "alpinweiß": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/045_alpinwei%C3%9F.png",
        "Dekor-Druck Eiche Artisan": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/072_Dekor-Druck%20Eiche%20Artisan.png",
        "lavagrau": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/062_lavagrau.png",
        "Hochglanz Soft Grey": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0A4_Hochglanz%20soft%20grey.png",
        "Effektgrau Hochglanz": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0R6_Hochglanz%20Effektgrau.png",
        "Hochglanz weiß": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0A1_Hochglanz%20wei%C3%9F.png",
        "Champagner": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/042_Champagner.png",
        "Kristallglasspiegel": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas104_Kristallspiegel.png",
        "Glas seidengrau": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas168_Glas%20seidengrau.png",
        "Glas basalt": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas181_Glas%20basalt.png",
        "Kristallweißglas": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas117_Glas%20wei%C3%9F.png",
        "Texline Leinenoptik naturfarben": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/047_Innenfarbe%20Dekor-Druck%20Texline.png",
        "alufarbig": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/007_alufarben.png",
        "schwarz matt": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/046_Schwarz%20matt.png",
        "schwarz": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/080_Schwarz.png",
        "Wildeiche Massiv": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/051_Wildeiche%20massiv.png",
        "chromfarben": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/004_chromfarben.png",
        "graphit": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/083_graphit.png",
        "Dekor-Druck Atlantic oak dunkel": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0E5_Dekor-Druck_Atlantic_oak_dunkel.png",
        "Webstoff Silver grey": "",
        "Webstoff charcoal": "",
        "Webstoff anthrazit": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0A7_Webstoff%20anthrazit.png",
        "Lederoptik camel": "",
        "Lederoptik black": "",
        "Lederoptik Cognacbraun": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/0K9_Leder_Cognacbraun.jpg",
        "Cordstoff Ivory": "",
        "Cordstoff Elephant": "",
        "Spiegel grau": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas103_Grauspiegel.png",
        "Glas fango": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas160_Glas%20Fango.png",
        "Glas schwarz": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/Glas180_Glas%20schwarz.png",
        "Glas champagner": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/GLAS188_Glas_Champagner_glaenzend.png",
        "Cottage schwarz": "",
        "Ledertasche cognacbraun": "",
        "Glas champagner glänzend": "https://www.rauchmoebel.de/fileadmin/contentserv/Farbmuster/GLAS188_Glas_Champagner_glaenzend.png"
      };
      return colorImages[colorName] || "";
    },
    openLightbox(colorName) {
      const imageUrl = this.getColorImage(colorName);

      if (imageUrl) {
        this.selectedColorImage = imageUrl;
        this.selectedColorName = colorName;
        this.lightboxOpen = true;
      }
    },
    closeLightbox() {
      this.lightboxOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.color-selector {
  padding: 30px 0 0;
  border-top: 1px solid #e0e0e0;
}

.color-category {
  & + .color-category {
    margin-top: 60px;
  }

  h4 {
    color: var(--main);
    font-weight: 400;
  }

  .color-list {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;
    text-align: center;

    .color-item {
      width: 33.333333%;
      padding: 0 5px;
      font-weight: 300;
      font-size: 0.8em;

      .color-swatch {
        margin: 15px 0 0;

        img,
        .no-image {
          border-radius: 100%;
          border: 1px solid #e0e0e0;
          -o-object-fit: cover;
          -o-object-position: center;
          object-fit: cover;
          object-position: center;
          width: 60px;
          height: 60px;
          display: inline-block;
        }

        .no-image {
          position: relative;
          overflow: hidden;

          &::before {
            content: "";
            display: block;
            width: 200%;
            height: 2px;
            background-color: #bbb;
            position: absolute;
            top: 50%;
            left: 30%;
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &::after {
            content: "";
            display: block;
            width: 200%;
            height: 2px;
            background-color: #bbb;
            position: absolute;
            top: 50%;
            left: 70%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }
  }
}
</style>
