<template>
  <div class="youtube-video">
    <div class="video-container">
      <video
        ref="videoPlayer"
        class="video-element"
        controls
        preload="metadata"
        :poster="posterSrc"
      >
        <source :src="videoSrc" type="video/mp4" />
        Ihr Browser unterstützt das Videos nicht.
      </video>
    </div>
    <figcaption v-if="title" class="video-title">{{ title }}</figcaption>
  </div>
</template>

<script>
export default {
  name: "YouTubeVideo",
  props: {
    videoId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    aspectRatio: {
      type: String,
      default: "56.25%", // 16:9 aspect ratio
    },
  },
  data() {
    return {
      videoSrc: "https://www.rauchmoebel.de/fileadmin/user_upload/Videos_mit_Produktbezug/Celle_Produktvideo.mp4",
      posterSrc: null,
    };
  },
  mounted() {
    try {
      this.posterSrc = require(`@/assets/videos/Video_Celle_Poster.jpg`);
    } catch (error) {
      console.error("Fehler beim Laden des Posters:", error);
    }
  },
};
</script>

<style lang="scss" scoped>
.youtube-video {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 30px;

  .video-container {
    position: relative;
    width: 100%;
    line-height: 0;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      border: 1px solid #e0e0e0;
    }
  }

  .video-title {
    font-size: 1em;
    color: #333;
    background-color: #e0e0e0;
    padding: 20px;
  }
}
</style>
