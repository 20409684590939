<!-- AddToWishlist.vue -->
<template>
  <div class="wishlist-button">
    <button
      @click="toggleWishlist"
      :class="{ 'in-wishlist': isInWishlist }"
      class="btn-wishlist"
    >
      <span
        class="mdi"
        :class="isInWishlist ? 'mdi-heart' : 'mdi-heart-outline'"
      ></span>
      {{
        isInWishlist
          ? "Gemerkt"
          : "Zur Merkliste hinzufügen"
      }}
    </button>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Cookies from "js-cookie";

export default {
  name: "AddToWishlist",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isInWishlist = ref(false);
    const WISHLIST_COOKIE = "rauch_wishlist";

    const getWishlist = () => {
      const wishlist = Cookies.get(WISHLIST_COOKIE);
      return wishlist ? JSON.parse(wishlist) : [];
    };

    const updateWishlistStatus = () => {
      const wishlist = getWishlist();
      isInWishlist.value = wishlist.some(
        (item) => item.id === props.product.id
      );
    };

    const removeFromWishlist = () => {
      const wishlist = getWishlist();
      const updatedWishlist = wishlist.filter(
        (item) => item.id !== props.product.id
      );
      Cookies.set(WISHLIST_COOKIE, JSON.stringify(updatedWishlist), {
        expires: 30,
      });
      isInWishlist.value = false;
    };

    const addToWishlist = () => {
      const wishlist = getWishlist();

      if (!isInWishlist.value) {
        wishlist.push({
          id: props.product.id,
          name: props.product.name,
          brand: props.product.brand,
          image: props.product.image,
        });

        Cookies.set(WISHLIST_COOKIE, JSON.stringify(wishlist), { expires: 30 });
        isInWishlist.value = true;
      }
    };

    const toggleWishlist = () => {
      if (isInWishlist.value) {
        removeFromWishlist();
      } else {
        addToWishlist();
      }
    };

    onMounted(() => {
      updateWishlistStatus();
    });

    return {
      isInWishlist,
      toggleWishlist,
    };
  },
};
</script>

<style lang="scss" scoped>
.wishlist-button {
  margin: 0 0 30px;

  .btn-wishlist {
    background: transparent;
    border: none;
    color: var(--main);
    padding: 6px 0;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    gap: 4px;

    &:hover {
      color: var(--textcolor);
    }

    &.in-wishlist {
      background: var(--main);
      color: white;

      &:hover {
        background: transparent;
        color: var(--main);
      }
    }

    .mdi {
      font-size: 20px;
    }
  }
}
</style>
